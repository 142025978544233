* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* -apple-system, BlinkMacSystemFont,  */

body {
  font-family: 'Poppins', 'Nunito', Roboto, 'Segoe UI', Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1d1d1f;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

h1 {
  font-weight: 600;
  line-height: 1.065;
  letter-spacing: -0.02em;
  color: #1d1d1f;
}

h2 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.065;
  letter-spacing: -0.009em;
  color: #1d1d1f;
}
img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
}

p {
  line-height: 1.38;
  color: #1d1d1f;
  font-size: 16px;
  letter-spacing: 0.011em;
}

ul {
  list-style: none;
}

.header-shadow {
  border-bottom: 1px solid #d6d6d6 !important;
  background-color: rgb(255 255 255 / 80%) !important;
}

.nav-between {
  justify-content: space-between;
}

.bgGrey {
  background-color: #252a33;
  color: #747474;
}
.bgGray {
  background-color: #323844;
  color: #747474;
}

.nav-item-margin {
  font-size: 14px;
  font-weight: 500;
}

.img-review {
  width: 92px;
}

.img-review img {
  width: 100%;
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.section-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 74px);
}

.sc-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.section-content {
  width: 100%;
  height: 78vh;
  max-width: 1280px;
  flex-direction: column;
  padding: 140px 40px 0;
  display: flex;
  position: absolute;
}

.sc-heading {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  object-fit: fill;
  flex-direction: column;
  justify-content: center;
  flex: 0 auto;
  padding-top: 0;
  display: flex;
  overflow: visible;
  margin-bottom: 64px;
}

.section-content h4 {
  max-width: 700px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 400;
}

.section-content h1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.section-content p {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.sc-indicators {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.indicator-item {
  width: 6px;
  height: 6px;
  background-color: #747474;
  margin-bottom: 16px;
  border-radius: 50%;
}

.ind1 .indicator-item:nth-child(1) {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.ind2 .indicator-item:nth-child(2) {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.ind3 .indicator-item:nth-child(3) {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.ind4 .indicator-item:nth-child(4) {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.ind5 .indicator-item:nth-child(5) {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.logo {
  line-height: 40px;
  position: fixed;
  float: left;
  margin: 16px 46px;
}

nav ul {
  line-height: 40px;
  overflow: hidden;
  padding: 0;
  text-align: right;
  margin: 0;
  padding-right: 40px;
  transition: all 0.4s ease-out;
}

nav ul li {
  display: inline-block;
  padding: 16px 24px;
}

.menu-icon {
  position: fixed;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  line-height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: none;
}

.open-bar i:nth-child(1) {
  transform: translateY(2px) rotate(45deg);
}

.open-bar i:nth-child(2) {
  transform: translateY(-6px) rotate(-45deg);
}

.menu-icon i {
  display: block;
  transition: 0.1s ease-out;
  width: 20px;
  border-bottom: 2px solid #1d1d1d;
  border-radius: 2px;
}

.menu-icon i:nth-child(2) {
  margin-top: 6px;
}

.contact-nav-btn {
  font-size: 14px;
  padding: 0 16px !important;
}

nav {
  background-color: rgb(255 255 255 / 0%);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}

/* pink, mobile | orange, web | blue, Infra | purple, AI  */
.nav-card-mobile {
  background: radial-gradient(
    circle,
    rgba(167, 0, 29, 0.98) 0%,
    rgb(255, 240, 243) 0%,
    rgba(255, 221, 228, 1) 100%
  );
  box-shadow: inset 8px -8px 16px #ffffff, inset -8px 8px 16px #eddfe2,
    rgba(246, 209, 216, 0.8) 0 0 160px 220px;
}

.nav-card-ai {
  background: radial-gradient(
    circle,
    rgba(167, 0, 29, 0.98) 0%,
    rgba(249, 238, 255, 1) 0%,
    rgba(243, 221, 255, 1) 100%
  );
  box-shadow: inset 8px 8px 16px #ffffff, inset -8px -8px 16px #e8dded,
    rgba(236, 215, 248, 0.8) 0 0 80px 140px;
}

.nav-card-web {
  background: radial-gradient(
    circle,
    rgba(167, 0, 29, 0.98) 0%,
    rgba(255, 248, 238, 1) 0%,
    rgba(255, 235, 207, 1) 100%
  );
  box-shadow: inset -8px 8px 16px #ffffff, inset 8px -8px 16px #ede7dd,
    rgba(247, 233, 212, 0.8) 0 0 96px 180px;
}

.nav-card-infra {
  background: radial-gradient(
    circle,
    rgba(167, 0, 29, 0.98) 0%,
    rgba(243, 249, 255, 1) 0%,
    rgba(218, 237, 255, 1) 100%
  );
  box-shadow: inset -8px -8px 16px #ffffff, inset 8px 8px 16px #e2e8ed,
    rgba(203, 224, 245, 0.8) 0 0 140px 200px;
}

@media (max-width: 786px) {
  h1 {
    font-size: 32px;
  }

  p {
    font-size: 14px;
  }

  .img-review {
    width: 80px;
  }

  .logo {
    position: fixed;
    top: 0;
    margin-top: 16px;
    margin-left: 32px;
  }

  nav ul {
    min-height: 0;
    max-height: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .showing {
    min-height: 100vh;
    max-height: 100vh;
  }

  nav ul li {
    display: block;
    box-sizing: border-box;
    padding: 24px;
    text-align: center;
    transition: all 0.4s ease-out;
    transition-delay: 0.2s;
    transform: translateY(-40px);
    opacity: 0;
  }

  .nav-item-margin {
    font-size: 20px;
    font-weight: 600;
  }

  .contact-nav-btn {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 24px !important;
  }

  .showing li {
    opacity: 1;
    transform: translateY(0);
  }

  .first-menu-item {
    padding-top: 160px;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
